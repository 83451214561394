var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex ma-0 pa-0 d-flex flex-column",attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[(_vm.embedded)?_c('router-link',{staticClass:"pointer",attrs:{"to":{
          name: _vm.Routes.EMBEDDED_NAVIGATE,
          params: { to: 'benutzerdaten', title: 'Benutzerdaten' }
        }}},[_vm._v(" Benutzerdaten ")]):_vm._e(),(_vm.embedded)?_c('v-icon',{attrs:{"left":"","right":"","small":""}},[_vm._v(" fas fa-chevron-right ")]):_vm._e(),_c('span',[_vm._v(" Datenschutz ")]),_c('v-spacer'),(_vm.embedded)?_c('v-btn',{attrs:{"to":{
          name: _vm.Routes.EMBEDDED_NAVIGATE,
          params: { to: 'benutzerdaten', title: 'Benutzerdaten' }
        },"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-chevron-left")]),_vm._v(" zurück ")],1):_vm._e()],1),_c('v-divider',{staticClass:"divider"})],1),_c('locations-list',{attrs:{"types":[
      _vm.LocationType.DATENSCHUTZBEAUFTRAGTER,
      _vm.LocationType.DATENSCHUTZBEHOERDE
    ],"create-missing":"","fields":{ name: true, co: true, title: true }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }